<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-show="userData.userRoleId!==userRole.PLAYER"
            variant="primary"
            :to="{ name: 'content-configuration'}"
          >
            <span> {{ $t('ADD_CONTENT') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          :md="userData.userRoleId===userRole.ADMIN ? 3 : 6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
        <b-col
          v-show="userData.userRoleId===userRole.ADMIN"
          cols="12"
          md="3"
        >
          <v-select
            id="id-company"
            v-model="companyFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('SELECT_COMPANY')"
            label="name"
            :options="allCompanies"
          />
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refContentListTable"
      :items="paginatedContents"
      responsive
      :fields="tableColumns"
      primary-key="ID"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDesc"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      class="position-relative"
      @sort-changed="onSortChanged"
    >
      <!-- Column:Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`content-row-${data.item.id}`"
              size="32"
              :variant="mapToContentTypeIcon(data.item.contentTypeId).variant"
              :to="{ name: 'content-view', params: { id: data.item.id } }"
            >
              <feather-icon
                :icon="mapToContentTypeIcon(data.item.contentTypeId).icon"
              />
            </b-avatar>
          </template>
          <b-link
            :to="{ name: 'content-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ strSubstring(data.item.name,20) }}
          </b-link>
          <small class="text-muted"> <b-badge
            pill
            :variant="`${mapFrequencyToVariant(data.item.contentFrequency.name)}`"
            class="text-capitalize small"
          >
            {{ data.item.contentType.name }}
          </b-badge>
          </small>
          <small class="text-muted"> <b-badge
            pill
            :variant="`${mapFrequencyToVariant(data.item.contentFrequency.name)}`"
            class="text-capitalize small"
          >
            {{ data.item.contentFrequency.name }}
          </b-badge>
          </small>
        </b-media>
      </template>

      <!-- Column:Reference ID -->
      <template #cell(reference_id)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.externalReference,15) }}
          </span>
        </b-media>
      </template>
      <!-- Column:Category -->
      <template #cell(category)="data">
        <div class="d-flex align-items-center">
          <!-- Avatar with Dynamic Variant Color -->
          <b-avatar
            class="mr-1"
            :variant="mapTagToVariant(data.item.tag).variant"
          >
            <!-- Feather Icon inside the Avatar -->
            <feather-icon
              size="18"
              :icon="mapTagToVariant(data.item.tag).Icon"
            />
          </b-avatar>
          <!-- Display Tag or Fallback to 'Other' -->
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.tag !== null ? data.item.tag : 'Other' }}
          </span>
        </div>
      </template>
      <!-- Column:StartDate -->
      <template #cell(start)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.start) }}
          </span>
        </b-media>
      </template>
      <!-- Column:EndDate -->
      <template #cell(end)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.end) }}
          </span>
        </b-media>
      </template>

      <!-- Column:Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`${mapStatusToVariant(data.item.contentStatus.name)}`"
          class="text-capitalize small"
        >
          {{ data.item.contentStatus.name }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <!-- <feather-icon
            v-show="isTodayOrFutureDate(data.item.end)"
            :id="`content-edit-${data.item.id}-send-icon`"
            icon="FileTextIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'survey-view', params: { id: data.item.id }})"
          /> -->
          <feather-icon
            v-show="isTodayOrFutureDate(data.item.end)"
            :id="`content-edit-${data.item.id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'content-configuration-id', params: { id: data.item.id }})"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(data.item.end)"
            :id="`content-ques-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer mr-1"
            @click="$router.push({ name: 'content-view', params: { id: data.item.id }})"
          />
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-show="isTodayOrFutureDate(data.item.end) && data.item.contentTypeId === contentType.GAME"
              @click="sendEmailInvitation(data.item)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">{{ $t('INVITATION_EMAIL') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-show="isTodayOrFutureDate(data.item.end) && data.item.contentTypeId === contentType.GAME"
              @click="sendSmsInvitation(data.item)"
            >
              <feather-icon icon="MessageCircleIcon" />
              <span class="align-middle ml-50">{{ $t('INVITATION_SMS') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-show="isTodayOrPastDate(data.item.start)"
              @click="analyzeSurvey(data.item)"
            >
              <feather-icon icon="SlackIcon" />
              <span class="align-middle ml-50">{{ $t('AI_ANALYZE') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmAndDeleteContent(data.item)">
              <feather-icon
                icon="Trash2Icon"
              />
              <span class="align-middle ml-50">{{ $t('BUTTON.DELETE') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="submitArchive(data.item)">
              <feather-icon icon="ArchiveIcon" />
              <span class="align-middle ml-50">
                {{ data.item.recordStatus > 0 ? $t('ARCHIVE') : $t('UNARCHIVE') }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalContents"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BMedia,
  BButton,
  BDropdown,
  BDropdownItem,
  BLink,
  BBadge,
  BPagination,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
// eslint-disable-next-line import/no-cycle
import {
  isTodayOrFutureDate,
  isTodayOrPastDate,
  mapTagToVariant,
  mapStatusToVariant,
  mapFrequencyToVariant,
  mapToContentTypeIcon,
} from '@/constants/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BPagination,
    BButton,
    vSelect,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BLink,
    BBadge,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      searchQuery: '',
      companyFilter: '',
      sortBy: 'id',
      isSortDesc: true,
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      allContents: [],
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'reference_id', label: 'Reference ID', sortable: false },
        { key: 'category', label: 'Category', sortable: true },
        { key: 'start', label: 'Start Date', sortable: true },
        { key: 'end', label: 'End Date', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'Actions' },
      ],
      contentType: constants.CONTENT_TYPE,
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      isTodayOrFutureDate,
      isTodayOrPastDate,
      mapTagToVariant,
      mapStatusToVariant,
      mapFrequencyToVariant,
      mapToContentTypeIcon,
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    }
  },
  async created() {
    this.fetchContents()
    this.fetchAllCompanies()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('company', ['allCompanies']),
    totalContents() {
      return this.filteredContents.length
    },
    dataMeta() {
      const localItemsCount = this.paginatedContents.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalContents,
      }
    },
    filteredContents() {
      let data = this.allContents || []
      // Filter by search query
      if (this.searchQuery || this.companyFilter) {
        const queryLowered = this.searchQuery ? this.searchQuery.toLowerCase() : ''
        data = data.filter(content => {
          const matchesName = content.name && content.name.toLowerCase().includes(queryLowered)
          const matchesCompany = this.companyFilter ? content.companyId === this.companyFilter.id : true

          // Return true if matchesName (when searchQuery exists) and matchesCompany
          return (this.searchQuery ? matchesName : true) && matchesCompany
        })
      }
      // Sort the data
      data = data.sort((a, b) => {
        const aValue = a[this.sortBy]
        const bValue = b[this.sortBy]

        // If sorting by ID, ensure numeric sorting
        if (this.sortBy === 'id') {
          return this.isSortDesc ? bValue - aValue : aValue - bValue
        }

        // If sorting by start_date or end_date, treat them as dates
        if (this.sortBy === 'start' || this.sortBy === 'end') {
          const aDate = new Date(aValue)
          const bDate = new Date(bValue)
          return this.isSortDesc ? bDate - aDate : aDate - bDate
        }

        // Otherwise, sort alphabetically
        const aStr = aValue ? aValue.toString().toLowerCase() : ''
        const bStr = bValue ? bValue.toString().toLowerCase() : ''

        if (this.isSortDesc) {
          return aStr < bStr ? 1 : -1
        }
        return aStr > bStr ? 1 : -1
      })
      return data
    },
    paginatedContents() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.filteredContents.slice(start, end)
    },
  },
  methods: {
    ...mapActions('content', [
      'fetchAllContents',
      'sendContentInvitation',
      'createSurveyAnalyze',
      'deleteContent']),
    ...mapActions('company', ['fetchAllCompanies']),
    confirmAndDeleteContent(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('Contents')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.handleDeleteContent(data)
          }
        })
    },
    analyzeSurvey(data) {
      let message
      if (data.analyze) {
        message = `${this.$i18n.t('MESSAGE.RE_ANALYZE_CONFIRMATION')} ?`
      } else {
        message = `${this.$i18n.t('MESSAGE.ANALYZE_CONFIRMATION')} ${data.name}   ${this.$i18n.t('Contents')} ?`
      }
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.warningMessage(this.$i18n.t('MESSAGE.ANALYZE_WAIT'))
            this.onAnalyzeSurvey(data)
          }
        })
    },
    sendSmsInvitation(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_SMS_CONFIRMATION')} ${data.name}   ${this.$i18n.t('Contents')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const payload = {
              contentId: data.id,
              userId: 0,
              temmId: 0,
            }
            this.sendSurveyInvitation(payload).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_INVITED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    sendEmailInvitation(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_EMAIL_CONFIRMATION')} ${data.name}   ${this.$i18n.t('Contents')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const payload = {
              contentId: data.id,
              userId: 0,
              isInviteAll: true,
            }
            this.sendContentInvitation(payload).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_INVITED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    onAnalyzeSurvey(data) {
      if (data) {
        this.createSurveyAnalyze(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.ANALYZE_CREATED'))
            // eslint-disable-next-line no-param-reassign
            data.analyze = true
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    handleDeleteContent(data) {
      if (data) {
        this.deleteContent(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.CONTENT_DELETED'))
            this.fetchContents()
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    onSortChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy
      this.isSortDesc = sortDesc
    },
    async fetchContents() {
      const payload = { page: this.currentPage }
      try {
        const response = await this.fetchAllContents(payload)
        if (response) {
          this.allContents = response.data
        } else {
          this.allContents = []
        }
      } catch (error) {
        this.showErrorMessage()
        this.allContents = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
